@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
body {
    background-color: #1A202C !important;
    margin: 0;
    padding: 0;
    color: #fff !important;
    /* touch-action: none; */
        /* font-family: 'Poppins', sans-serif !important; */
    }
.imageContent {
    touch-action: none;
}
.rotedIcon {
    rotate: 45deg;
}

.blob {
    transform: scale(2);
    animation: pulse-black 2s infinite;
}
.textContent {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-height: 1; */
    -webkit-line-clamp: 16;
    -webkit-box-orient: vertical;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
}

ol {
    list-style: none;
    counter-reset: my-awesome-counter;
    margin-left: 40px;
  }
  li {
    list-style-position: inherit;
    counter-increment: my-awesome-counter;
    margin: 0.25rem;
    font-size: 18px;
    /* display: table-row; */
  }
  li::before {
    content: counter(my-awesome-counter);
    background: #ffc008;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: inline-block;
    line-height: 3rem;
    color: white;
    text-align: center;
    margin-right: 0.5rem;
    font-weight: 600;
    font-size: 18px;
    margin-left: -55px;
  }